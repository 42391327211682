import React from "react";
import ReadMore from '../components/readMore';
import yn from "yn";
import { Divider, Typography, Paper, Grid, Hidden, Button } from '@material-ui/core';
import fileDownload from '@iconify/icons-mdi/file-download';
import RecaptchaLink from '../components/recaptcha';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import ViewMore from "../components/viewMore";



var TESTING = yn(window._env_.REACT_APP_TESTING)

function RenderScore(props) {
  return (
    <li>
      <span className="sui-result__key">Score</span>{" "}
      <span className="sui-result__value">{props}</span>
    </li>)
}

function RenderHighlight(props) {
  return (
    <li>
      <span className="sui-result__key">Highlights</span>{" "}
      <span className="doctrina_text" dangerouslySetInnerHTML={{ __html: props.highlight }}></span>
    </li>
  )
}

export default ({ result }) => (
  <div className="result-root">
    <Paper elevation={3} className="result-paper" style={{ borderLeft: 'solid 5px', borderColor: result.color.raw, padding: '25px' }}>
      {(result.index.raw == "dictamenes") &&
        <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} style={{ paddingBlockEnd: '20px', paddingRight: '15px' }}>
          <Typography variant="h1" gutterBottom>
            <span
              // Snippeted results contain search term highlights with html and are
              // 100% safe and santitized, so we dangerously set them here
              dangerouslySetInnerHTML={{ __html: result.voces.raw.join(". ") }} />
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {result.organismo.raw}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Fecha: {result.fecha.raw}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} container alignContent="flex-start" spacing={2}>
          <Hidden only={['xs', 'sm']}>
            <Grid item sm={12} md={12} container justifyContent="center">
              <AssignmentReturnedIcon icon={fileDownload} color='primary'></AssignmentReturnedIcon>
            </Grid>
          </Hidden>
          <Grid item xs={6} sm={6} md={12} container justifyContent="center">
            <RecaptchaLink type="doctrina" id={result.id.raw} label="Ver Doctrina" />
          </Grid>
          <Grid item xs={6} sm={6} md={12} container justifyContent="center">
            <RecaptchaLink type="dictamen" id={result.id.raw} label="Ver Dictamen" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider style={{ margin: "15px -2px", backgroundColor: "#C2CFE0" }} />
          <Typography variant="h1">
            Texto de la doctrina:
          </Typography>
          <span className="doctrina_text">
            <ReadMore text={result.doctrina.raw} readMoreText="" readLessText="" />
          </span>
        </Grid>
      </Grid>
    }
      {(result.index.raw == "historico") &&
        <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} style={{ paddingBlockEnd: '20px', paddingRight: '15px' }}>
          <Typography variant="h1" gutterBottom>
            <span
              // Snippeted results contain search term highlights with html and are
              // 100% safe and santitized, so we dangerously set them here
              dangerouslySetInnerHTML={{ __html: result.voces.raw.join(". ") }} />
          </Typography>
          {/* <Typography variant="subtitle1" gutterBottom>
            {result.organismo.raw}
          </Typography> */}
          <Typography variant="subtitle2" color="textSecondary">
            Año: {new Date(result.fecha.raw+"T00:00:00").toLocaleDateString("es-AR",{year:'numeric'})}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Tomo: {result.tomo.raw}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Página: {result.pagina.raw}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} container alignContent="flex-start" spacing={2}>
          <Hidden only={['xs', 'sm']}>
            <Grid item sm={12} md={12} container justifyContent="center">
              <AssignmentReturnedIcon icon={fileDownload} color='primary'></AssignmentReturnedIcon>
            </Grid>
          </Hidden>
          <Grid item xs={6} sm={6} md={12} container justifyContent="center">
            <RecaptchaLink type="dictamen" id={result.id.raw} historical={true} label="Ver Dictamen" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider style={{ margin: "15px -2px", backgroundColor: "#C2CFE0" }} />
          <Typography variant="h1">
            Texto:
          </Typography>
          <span className="doctrina_text">
            <ViewMore preview={result.vista_previa.raw} viewMoreText="" viewLessText="" />
          </span>
        </Grid>
      </Grid>
    }
    </Paper>
  </div>
);

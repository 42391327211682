import React from "react";
import yn from "yn";
import { Divider, Button, Typography, Paper, Grid } from '@material-ui/core';
import { Icon } from '@iconify/react';
import fileDownload from '@iconify/icons-mdi/file-download';
import RecaptchaLink from '../components/recaptcha';
import DeleteDictamenComponent from "../components/deleteDictamenComponent";
import ModifyDictamenComponent from "../components/modifyDictamenComponent";
import updateDictamen from "../helpers/updateDictamen";
import deleteDictamen from "../helpers/deleteDictamen";


var TESTING = yn(window._env_.REACT_APP_TESTING)

function RenderScore(props) {
  return (
      <li>
        <span className="sui-result__key">Score</span>{" "}
        <span className="sui-result__value">{props.score}</span>
      </li>)
}

function RenderHighlight(props) {
  return (
    <li>
      <span className="sui-result__key">Highlights</span>{" "}
      <span className="doctrina_text" dangerouslySetInnerHTML={{ __html: props.highlight}}></span>
    </li>
  )
}

export default ({ result, onSearch }) => (
  <div className="result-root">
  <Paper elevation={3} className="result-paper" style={{borderLeft: 'solid 5px', borderColor: result.color.raw, padding: '25px' }}>
        <Grid container column="true" >
          <Grid container direction="row" wrap="nowrap">
            <Grid container>
              <Grid item xs>
                <Typography variant="h1" gutterBottom>
                    <span
                      // Snippeted results contain search term highlights with html and are
                      // 100% safe and santitized, so we dangerously set them here
                      dangerouslySetInnerHTML={{ __html: result.voces.raw.join(". ") }}
                    />
                  </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {result.organismo.raw}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Fecha: {result.fecha.raw}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Tomo: {result.tomo.raw}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Página: {result.pagina.raw}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  Número: {result.numero.raw}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} container direction="column" alignContent="center" alignItems="center" justify="flex-start" spacing={2}>
              <Grid item>
                <Icon icon={fileDownload} style={{color: "#C2CFE0", width: 32, height:32}}/>
              </Grid>
              <Grid item>
                <RecaptchaLink type="doctrina" id={result.id.raw} label="Ver Doctrina" />
                </Grid>
              <Grid item>
                <RecaptchaLink type="dictamen" id={result.id.raw} label="Ver Dictámen" />
              </Grid>
            </Grid>
          </Grid>
          <Grid container column="true">
            <Grid item xs>
              <Divider style={{margin: "15px 20px", backgroundColor:"#C2CFE0"}}/>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <ModifyDictamenComponent id={result.id.raw} label="Modificar" fecha={result.fecha.raw} onSearch={onSearch} onSubmit={updateDictamen}/>
              </Grid>
              <Grid item xs={6}>
                <DeleteDictamenComponent id={result.id.raw} label="Eliminar" mensaje={"¿Confirmar la eliminación del dictamen tomo: " + result.tomo.raw + " página: " + result.pagina.raw + " número: " + result.numero.raw + "?"} onSearch={onSearch} onSubmit={deleteDictamen}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      </div>

);
